import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Input } from 'antd';
import { SearchOutlined } from "@ant-design/icons";

import { GET_HASHTAGS } from 'graphql/query/hashtag-gql';

import Hashtag from 'components/hashtag';
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import { Localize } from "components/service";
import './hashtag.scss';

const { Search } = Input;


const basePath = Hashtag.Const.basePath;
const tableHelper = Hashtag.Helpers.TableMain();


const HashtagsPage = () => {

    useActiveMenuItem([ "hashtags" ], [ "hashtags" ]);

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Hashtags" }).props.children,
            path: `${ basePath }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    let match = useRouteMatch(`${ basePath }/page/:pageNum`);
    const objOrderBy = [ { column: "CREATED_AT", order: "DESC" } ];

    const [ searchText, setSearchText ] = useState("");


    return (
        <PageWrapDefault
            className="page-hashtags"
            dataExist={ true }
            title={ <Localize>PAGES.Title_Hashtags</Localize> }
            staticPath=""
        >
            <Tables.Main
                model="hashtags"
                query={ GET_HASHTAGS }
                varSet={ { perPage: 30 } }
                routeUrl={ `${ basePath }` }
                currentPage={ match ? parseInt(match.params.pageNum) : 1 }
                searchText={ searchText }
                objOrderBy={ objOrderBy }
                tableHelper={ tableHelper }
                totalCounter={ true }
            >
                <div className="table-action-bar">

                    <div className="col">
                        <Search
                            className="filter-search"
                            prefix={ <SearchOutlined /> }
                            placeholder={ Localize({ children: "SEARCH.Input_Placeholder_Table_Title" }).props.children }
                            onChange={ (e) => setSearchText(e.target.value) }
                        />
                    </div>

                    <div className="col"> </div>
                </div>
            </Tables.Main>
        </PageWrapDefault>
    )
};

export default HashtagsPage;