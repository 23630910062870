import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { GET_USER_QUESTIONS } from 'graphql/query/user-gql';

import User from 'components/user';
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import { Localize } from "components/service";
import './user.scss';

const { Search } = Input;


const UsersQuestionsPage = () => {

    const tableHelper = User.Helpers.TableQuestion;

    useActiveMenuItem([ "users" ], [ "users" ]);


    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Questions" }).props.children,
            path: "/user-questions"
        },
    ]

    useBreadCrumbs(breadcrumbs);

    const routeUrl = `/user-questions`;
    let match = useRouteMatch(`${ routeUrl }/page/:pageNum`);


    const [ searchText, setSearchText ] = useState("");
    // const [ variables, setVariables ] = useState("");


    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Users" }).props.children,
            path: "users"
        },

        {
            label: Localize({ children: "NAVBAR_PAGES.Label_UserQuestions" }).props.children,
            path: "user-questions"
        } ];


    return (
        <PageWrapDefault
            className="page-users-questions"
            dataExist={ true }
            title={ <Localize>PAGES.Title_Users</Localize> }
            pageNavbar={ pageNavbar }
            staticPath=""
        >

            <Tables.Main
                model="userQuestions"
                extraClass="table-questions"
                query={ GET_USER_QUESTIONS }
                varSet={ { perPage: 30 } }
                routeUrl={ routeUrl }
                currentPage={ match ? parseInt(match.params.pageNum) : 1 }
                searchText={ searchText }
                // setVariables={ setVariables }
                tableHelper={ tableHelper }
                totalCounter={ true }
            >
                <div className="table-action-bar">

                    <div className="col">
                        <Search
                            className="filter-search"
                            prefix={ <SearchOutlined /> }
                            placeholder={ Localize({ children: "SEARCH.Input_Placeholder_MultiSelect" }).props.children }
                            onChange={ (e) => setSearchText(e.target.value) }
                        />
                    </div>

                    <div className="col">

                        {/* <ModalStandard
                            width={ 380 }
                            extraClass={ 'modal-form' }
                            modalButton={
                                <Button
                                    type="primary">
                                    <span>Create Question</span>
                                </Button>
                            }>
                            <strong className="modal-title"><Localize>FORMS.Title_Question</Localize></strong>

                            <User.Forms.Question variables={ variables } />
                        </ModalStandard> */}
                    </div>
                </div>
            </Tables.Main>
        </PageWrapDefault>
    )
};

export default UsersQuestionsPage;