import { gql } from '@apollo/client';



export const GET_SUMMARY_STATISTIC = gql`
    query GetSummaryStatistic{
        summaryStatistic{
            id
            key
            value
            active
            updated_at
        }
    }`;


export const GET_AVG_STATISTICS = gql`
    query GetAvgStatisticsRangeQuery(
    $key: [AverageStatisticEnum] = [users_visit, users_new,posts_like]
    $start_day: Date
    $end_day: Date
){
        avgStatisticsRangeQuery(
            key: $key
            start_day: $start_day
            end_day: $end_day
        ){
            users_visit
            users_new
            posts_like
        }
    }`;