import React from "react";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import AnalyticRoute from 'components/routes/analytic/analytic-route';
import { Localize } from "components/service";



const AnalyticPage = ({ history }) => {

    useActiveMenuItem([ "analytics" ], [ "analytics" ]);

    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Sales" }).props.children,
            path: "sales"
        }
    ];


    return (
        <PageWrapDefault
            className="page-analytic"
            dataExist={ true }
            title={ <Localize>PAGES.Title_Analytics</Localize> }
            pageNavbar={ pageNavbar }
            staticPath={ `/analytics` }
            totalCounter={ true }
        >
            <AnalyticRoute history={ history } />

        </PageWrapDefault>
    );
};

export default AnalyticPage;