import React from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { GET_COMPANIES } from "graphql/query/company-gql";
import Company from "components/company";
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import { Localize } from "components/service";
// import { gqlBuilderWhere } from "utils";
import "./company.scss";

const basePath = Company.Const.basePath;
const tableHelper = Company.Helpers.TableMain;

const CompaniesPage = () => {
  useActiveMenuItem(["companies"], ["companies"]);

  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_Companies" }).props
        .children,
      path: `${basePath}`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  let match = useRouteMatch(`${basePath}/page/:pageNum`);

  const objOrderBy = [{ column: "ID", order: "DESC" }];

//   const [searchText, setSearchText] = useState("");
//   const [filters, setFilters] = useState();
//   const [resetSorting, setResetSorting] = useState(false);

//   const companyFilters = JSON.parse(localStorage.getItem("companyFilters"));
//   const companySorting = JSON.parse(localStorage.getItem("companySorting"));

  return (
    <PageWrapDefault
      className="page-companies"
      dataExist={true}
      title={<Localize>PAGES.Title_Companies</Localize>}
      staticPath=""
    >
      <Tables.Main
        model="companies"
        query={GET_COMPANIES}
        varSet={{ perPage: 30 }}
        routeUrl={`${basePath}`}
        currentPage={match ? parseInt(match.params.pageNum) : 1}
        // searchText={searchText}
        // objFilter={filters || gqlBuilderWhere(companyFilters)}
        objOrderBy={objOrderBy}
        tableHelper={tableHelper}
        // resetSorting={resetSorting}
        totalCounter={ true }
      >
        <div className="table-action-bar">
          <div className="col"></div>

          <div className="col">
            <Link className="ant-btn ant-btn-primary" to={`${basePath}/create`}>
              <span>
                <PlusOutlined />
                <Localize>COMPANY.Button_Text_Create</Localize>
              </span>
            </Link>
          </div>
        </div>
      </Tables.Main>
    </PageWrapDefault>
  );
};

export default CompaniesPage;
