import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";

import { GET_POSTS } from 'graphql/query/post-gql';

import Post from 'components/post';
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import { Localize } from "components/service";
import { gqlBuilderWhere } from "utils";
import './post.scss';


const basePath = Post.Const.basePath;
const tableHelper = Post.Helpers.TableMain();


const PostsPage = () => {


    useActiveMenuItem([ "posts" ], [ "posts" ]);

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Posts" }).props.children,
            path: `${ basePath }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    let match = useRouteMatch(`${ basePath }/page/:pageNum`);

    const objOrderBy = [ { column: "CREATED_AT", order: "DESC" } ];

    const [ searchText, setSearchText ] = useState("");
    const [ filters, setFilters ] = useState();
    const [ resetSorting, setResetSorting ] = useState(false);

    const postsFilters = JSON.parse(localStorage.getItem("postsFilters"));
    const postsSorting = JSON.parse(localStorage.getItem("postsSorting"));

    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Posts" }).props.children,
            path: "posts"
        },

        {
            label: Localize({ children: "NAVBAR_PAGES.Label_AllComments" }).props.children,
            path: "all-comments"
        } ];

    return (
        <PageWrapDefault
            className="page-posts"
            dataExist={ true }
            title={ <Localize>PAGES.Title_Posts</Localize> }
            pageNavbar={ pageNavbar }
            staticPath=""
        >
            <Tables.Main
                model="posts"
                extraClass="table-posts"
                query={ GET_POSTS }
                varSet={ { perPage: 30 } }
                routeUrl={ `${ basePath }` }
                currentPage={ match ? parseInt(match.params.pageNum) : 1 }
                searchText={ searchText }
                objFilter={ filters || gqlBuilderWhere(postsFilters) }
                objOrderBy={ postsSorting || objOrderBy }
                sortingName="postsSorting"
                tableHelper={ tableHelper }
                resetSorting={ resetSorting }
                totalCounter={ true }
            >
                <div className="table-action-bar">

                    <div className="col">
                        <Post.Filters.Table
                            filters={ filters }
                            setSearchText={ setSearchText }
                            setFilters={ setFilters }
                            postsFilters={ postsFilters }
                            setResetSorting={ setResetSorting }
                            objOrderBy={ objOrderBy }
                        />
                    </div>

                    <div className="col"> </div>
                </div>
            </Tables.Main>
        </PageWrapDefault>
    )
};

export default PostsPage;